/* Général */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  /* background-color: #0B1215; */
  background-color: #0026ff;
  /* background-color: #191970; */
  color: #fff;
  overflow-x: hidden;
  /* Empêche le défilement horizontal */
  height: 100vh;
}

/* En-tête */
/* .header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  text-align: center;
  z-index: 10;
  
}

.navbar .nav-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.navbar .nav-links li {
  display: inline;
  margin: 0 20px;
}

.navbar .nav-links li a {
  text-decoration: none;
  color: #b2abb7;
  font-size: 18px;
  font-weight: bold;
  transition: color 0.3s;
}

.navbar .nav-links li a:hover {
  color: #dfb4fd;
} */



/* Sections */
main {
  padding-top: 40px;
}

/* section {
  padding: 60px 20px;
  text-align: center;
}

section h2 {
  margin-bottom: 20px;
} */

.menuPhone {
  display: none;
}
/* MEDIA QUERIES */

/* Écrans inférieurs à 768px  */
@media (max-width: 768px) {}

/* Écrans inférieurs à 480px  */
@media (max-width: 480px) {
  .menuPhone {
    display: block;
  }
  .queryPhone {
    display: none;
  }
}


.back-to-projects {
  position: fixed;
  top: 80%;
  right: 24px;
  padding: 20px;
  z-index: 10;
  transform: translateY(0%);
}

.btn-back {
  position: relative;
    color: #cfcba4;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    font-size: 4vw;
    letter-spacing: .02em;
    line-height: .9em;
    font-family: 'Bebas Neue', cursive;
    text-align: right;
    display: block;
    transition: color 0.5s ease, transform 0.3s ease-in-out; 
}

.btn-back:hover {
  color: #FFFACD;
    transform: scale(1.3) rotate(-2deg);
    text-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}