@keyframes slideInFromLeft {
    from {
        transform: translateY(100%) perspective(500px) rotateY(60deg); 
        opacity: 0;
    }
    to {
        transform: translateY(0) perspective(500px) rotateY(30deg); 
        opacity: 1;
    }
}

.menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    transform: perspective(500px) rotateY(30deg); 
    text-align: center;
    position: absolute;
    animation: slideInFromLeft 1.5s ease-in-out; 
    opacity: 1;
    top: 6vw;
}



.menu ul li {
    position: relative;
}

.menu ul {
    text-align: left;
    text-decoration: none;
    list-style-type: none;
}


.menu h2 {
    position: relative;
    left: 4vw;
    margin-bottom: 20px;
    font-family: 'Bebas Neue', cursive;
    color: #dbd27e;
    font-weight: 900;
    font-size: 8vw;
    text-transform: uppercase;
    letter-spacing: .02em;
    /* text-decoration: underline; */
    text-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    
}

.titreMenu {
    left: 4vw;
    
    position: relative;
    color: #FFFACD;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    font-size: 8vw;
    letter-spacing: .02em;
    line-height: .9em;
    transition: color .5s,opacity .5s; 
    font-family: 'Bebas Neue', cursive; 
    
    text-align: left;

    /* Peut être enlevé*/
    text-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.titreMenu:hover {
    color: transparent; 
    -webkit-text-stroke: 1px #FFFACD;
    text-stroke: 1px #FFFACD; 
    border-radius: 5px;
    transition: all 0.6s ease-in-out;
}




.project-details {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 4vw;
    gap: 5px;
}

.project-date {
    position: relative;
    font-weight: bold;
    left: 4vw;
    font-family: 'Bebas Neue';
    font-weight: 700;
    color: #dbd27e;
    font-size: 1.3rem;
}

.project-description {
    font-style: italic;
    font-weight: 700;
    font-family: 'Bebas Neue', cursive;
    color: #dbd27e;
    font-size: 1rem;
    color:#dbd27e;
}

.enCours {
    font-style: italic;
    font-weight: 700;
    font-family: 'Bebas Neue', cursive;
    font-size: 1rem;
    font-weight: 900;
    color: red;
    z-index: 15;
}

/* .menu:hover .menu>ul>.menu-item {
    transform: perspective(500px) rotateY(0deg); /* Appliquer la rotation uniquement lorsque l'on survole le menu 
} 
*/


/* MEDIA QUERIES */

/* Écrans inférieurs à 768px  */
@media (max-width: 768px) {
    .menu {
        transform: none; 
    }

    .menu h2, 
    .titreMenu {
        font-size: 6vw; 
    }

    .project-date {
        font-size: 1rem; 
    }

    .project-description {
        font-size: 0.8rem; 
    }
}

/* Écrans inférieurs à 480px  */
@media (max-width: 480px) {
    .menu {
        transform: none; 
    }
    @keyframes slideInFromLeft {
        from {
            transform: translateY(100%) perspective(500px) rotateY(60deg); 
            opacity: 0;
        }
        to {
            transform: translateY(0) perspective(500px) rotateY(0deg); 
            opacity: 1;
        }
    }

    .menu h2, 
    .titreMenu {
        font-size: 13vw; 
    }

    .project-date {
        font-size: 1rem;
    }

    .project-description {
        font-size: 1.2rem;
    }
}