.titreNom {
    position: relative;
    color: #FFFACD;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 4vw;
    letter-spacing: .02em;
    line-height: .9em;
    font-family: 'Bebas Neue', cursive;
    text-align: right;
    transition: text-shadow 0.3s; 
}

.titreContainer {
    position: fixed;
    top: 0;
    right: 24px;
    padding: 5px;
    z-index: 100;
}

/* soulignement */
.titreNom::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 0.4vw;
    background-color: #FFFACD;
    transform: scaleX(0);
    transform-origin: bottom left;
    transition: transform 0.4s ease; 
    border-radius: 10px;
}

.titreNom:hover::after {
    transform: scaleX(1);
    transform-origin: bottom right; 
}
